import { INSERT_DATA } from "../actions/data-actions"

export const dataReducer = (state = { card: {}, list: {}, subCategory: {}, category: {}, user: {} }, { type, payload }) => {
  switch (type) {
    case INSERT_DATA:
      const subCategoryToCards = {}
      Object.values(payload.data.card).forEach((card: any) => {
        card.categories.forEach((subCategory: any) => {
          subCategoryToCards[subCategory] = subCategoryToCards[subCategory] ? [...subCategoryToCards[subCategory], card._id] : [card._id]
        })
      })
      return { ...payload.data, subCategoryToCards }
    default:
      return state
  }
} 