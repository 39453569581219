import { Provider } from 'react-redux';
import React from 'react';
import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import { searchReducer } from './reducers/SearchReducer';
import { UserReducer } from './reducers/UserReducer';
import { dataReducer } from './reducers/dataReducer';

const rootReducer = combineReducers({
  search: searchReducer,
  user: UserReducer,
  data: dataReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));
export default function(props) {
  return <Provider store={store}>{props.children}</Provider>;
}