export class SubCategory {
  public id: string
  public name: string
  public description: string

  constructor (id: string, name: string, description: string = '') {
    this.id = id
    this.name = name
    this.description = description
  }

  

}

export function createSubCategories(subCategories):SubCategory[] {
  return subCategories.map(subCategory => {
    const {_id, title} = subCategory
    return new SubCategory(_id, title)
  })
}
