import React from 'react'
import { Link } from 'react-router-dom'
import imageUrlBuilder from '@sanity/image-url'
import Card from '../model/Card'
import { useSelector } from 'react-redux'
const client = require('@sanity/client')({
    projectId: 'vdl3i2aa',
    dataset: 'production',
    useCdn: true,
})
const builder = imageUrlBuilder(client)
function urlFor(source) {
    return builder.image(source)
}

export interface CardProps {
    card: Card
    compact?: boolean
}

export default function CardListItem({ card, compact = false }: CardProps) {
    const user = useSelector((state: any) => state.user)
    const list = useSelector((state: any) => state.data.list)
    let status = null
    if (
        !card.isFree &&
        !(
            user.id &&
            list &&
            list['f3ad7428-da4a-416c-a523-c8fd7991b133'] &&
            list['f3ad7428-da4a-416c-a523-c8fd7991b133'].cards.includes(card.id)
        )
    ) {
        if (!user.hasPlus && !user.cards[card.id]) {
            status = (
                <span className="inline-block px-2 mr-2 py-1 leading-none bg-blue-200 text-blue-800 rounded-full font-semibold uppercase tracking-wide text-xs">
                    +
                </span>
            )
        }
    }

    return (
        <Link to={`/cards/${card.id}`}>
            <div className={`${compact ? 'py-5' : ''}`}>
                {!compact && (
                    <div className="relative pb-1/2">
                        <img
                            className="absolute inset-0 h-full w-full rounded-lg shadow-md object-cover"
                            src={urlFor(card.image)
                                .width(700)
                                .url()}
                            alt=""
                        />
                    </div>
                )}
                <div className="relative px-4 -mt-16">
                    {compact && (
                        <div className="relative h-12 z-10 -mb-2">
                            <img
                                className="shadow-md  absolute h-full w-full rounded-lg object-cover"
                                src={urlFor(card.image)
                                    .width(450)
                                    .height(100)
                                    .url()}
                                alt=""
                            />
                        </div>
                    )}

                    <div
                        className={`bg-white rounded-lg px-4 py-4 shadow-lg overflow-hidden ${
                            compact ? 'h-30' : 'h-56'
                        }`}
                    >
                        <div className="flex items-baseline">{status}</div>
                        <div className="mt-1 text-gray-900 font-semibold text-lg ">
                            {card.name}
                        </div>
                        <div className="mt-1">
                            <span className="text-sm text-gray-600">
                                {card.preview}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}
