import React, { useState, useRef } from 'react'
import { signOut } from '../../redux/actions/user-actions'
import { Link } from 'react-router-dom'
import { useCloseModalWhenOutsideClick } from '../../hooks/useCloseModalWhenOutsideClick'
import UnlockList from '../UnlockList'
export default function SettingsButton() {
    const [isOpen, setIsOpen] = useState(false)
    const wrapperRef = useRef(null)
    useCloseModalWhenOutsideClick(wrapperRef, setIsOpen)
    return (
        <div className="hidden sm:block">
            <div id="dropdown" className="relative inline-block">
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className={` ${isOpen &&
                        'bg-blue-500'} mt-1 block px-3 py-1 rounded font-semibold text-white hover:bg-blue-800 lg:mt-0 lg:text-lg lg:px-2 lg:ml-2 xl:text-blue-900 xl:hover:bg-blue-200`}
                >
                    Konto
                </button>
                {/* <!-- Replace "right-0 origin-top-right" with "left-0 origin-top-left" to left-align the dropdown --> */}
                {isOpen && (
                    <div
                        className="mt-2 absolute right-0 origin-top-right z-50"
                        ref={wrapperRef}
                    >
                        <div className="w-64 text-left bg-white rounded-lg shadow-lg text-sm font-semibold text-blue-900">
                            {/* <div className="border-t-2 py-1">
                                <Link
                                    to="/einstellungen/karten"
                                    className="block px-6 py-3 leading-tight hover:bg-blue-200"
                                >
                                    Lehrmethoden erwerben
                                </Link>
                            </div>
                            <div className="border-t-2 py-1">
                                <Link
                                    to=""
                                    className="block px-6 py-3 leading-tight hover:bg-blue-200"
                                >
                                    Meine Lehrmethoden
                                </Link>
                            </div> */}
                            <div className="border-t-2 py-1">
                                <Link
                                    className="block px-6 py-3 leading-tight hover:bg-blue-200"
                                    to="/lists"
                                >
                                    Meine Listen
                                </Link>
                            </div>
                            <div className="border-t-2 py-1">
                                <UnlockList style={'block px-6 py-3 leading-tight hover:bg-blue-200'}/>
                            </div>
                            <div className="border-t-2 py-1">
                                <a
                                    href="#"
                                    className="block px-6 py-3 leading-tight hover:bg-blue-200"
                                >
                                    Profil
                                </a>
                            </div>
                            <div className="border-t-2  py-1">
                                <button
                                    onClick={() => signOut().then()}
                                    className="block w-full px-6 py-3 text-left leading-tight hover:bg-blue-200"
                                >
                                    Abmelden
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
