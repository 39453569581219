import React from 'react'
import PropTypes from 'prop-types'
import SimpleBlockContent from './foundation_blocks/SimpleBlockContent'
import styles from './TextSection.module.css'

function TextSection (props) {
  const {heading, label, text} = props

  return (
    <div className=''>
      <section className=''>
        {text && <SimpleBlockContent blocks={text} />}
      </section>
    </div>
  )
}

TextSection.propTypes = {
  heading: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.arrayOf(PropTypes.object)
}

export default TextSection
