import React from 'react'

function ErrorNotification({message}) {
    return <div className="p-4 shadow-md bg-red-300 font-extrabold text text-blue-800 uppercase  w-full">{message}</div>
}

export default function(message) {
    return {
        content: <ErrorNotification message={message}/>,
        type: 'error',
        insert: 'top',
        container: 'bottom-right',
        animationIn: ['animated', 'wobble'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
            duration: 5000,
        },
    }
}
