export const UPDATE_SEARCH_VALUE = 'UPDATE_SEARCH_VALUE';
export const UPDATE_QUERY_PARAM = 'UPDATE_QUERY_PARAM';
export const CLEAR_QUERY_PARAM = 'CLEAR_QUERY_PARAM'
export const UPDATE_QUERY_PARAM_RADIO = 'UPDATE_QUERY_PARAM_RADIO'
export const REMOVE_QUERY_PARAM = 'REMOVE_QUERY_PARAM;'
export const updateSearchValue = ({ searchValue }) => {
  return {
    type: UPDATE_SEARCH_VALUE,
    payload: {
      searchValue
    },
  };
};

export const updatedQueryParam = (queryParam) => {
  return {
    type: UPDATE_QUERY_PARAM,
    payload: {
      queryParam
    },
  };
}

export const removeQueryParam = (queryParam) => {
  return {
    type: REMOVE_QUERY_PARAM,
    payload: {
      queryParam
    },
  };
}

export const updateQueryParamRadio = (queryParams) => {
  return {
    type: UPDATE_QUERY_PARAM_RADIO,
    payload: {
      queryParams
    },
  };
}

export const clearQueryParam = () => {
  return {
    type: CLEAR_QUERY_PARAM,
  };
}