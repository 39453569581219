import React from 'react'
import CardListItem from './CardListItem'
import data from '../data'
import Card, { createCard } from '../model/Card'
import { useSelector } from 'react-redux'
import CardGroup from './CardGroup'

export default function Categories() {
    const data = useSelector((state: any) => state.data)
    const userId = useSelector((state: any) => state.user.id)
    return (
        <>
            {Object.values(
                data.category).sort((c1: any, c2: any) => c1.position-c2.position).map((category: any) => (
                    <div key={category._id} className={'py-6'}>
                        <h5 className="text-blue-900 font-bold text-xl px-4">{category.name}</h5>
                        {category.subCategories.map((subCategoryId, i) => { 
                            const subCategory = data.subCategory[subCategoryId]
                            const cards = (data.subCategoryToCards[subCategory._id] && data.subCategoryToCards[subCategory._id].map(cardId => createCard(data.card[cardId])).sort((c1: Card, c2: Card) => c1.name > c2.name? 1: -1)) || []
                        
                            return (
                            <CardGroup key={subCategory._id} cards={cards} name={subCategory.name} dontUseMore={false}/>
                            )
  })}
                    </div>
                ))
            }

        </>
    )
}
