import { useEffect } from "react"

export function useCloseModalWhenOutsideClick(ref, setIsVisible) {
  function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
          setIsVisible(false)
      }
  }

  useEffect(() => {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
          // Unbind the event listener on clean up
          document.removeEventListener('mousedown', handleClickOutside)
      }
  })
}