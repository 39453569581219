import React, { useEffect } from 'react'
import './styles/tailwind.css'
import SiteHeader from './components/header/SiteHeader'
import SearchFilters from './components/search_filter/SearchFilters'

import * as firebase from 'firebase/app'
import { useDispatch } from 'react-redux'
import admin from 'firebase'
import { loginUser, logoutUser } from './redux/actions/user-actions'
import { insertData } from './redux/actions/data-actions'
import { store } from 'react-notifications-component'
import Content from './components/Content'
import ErrorNotification from './components/notifications/ErrorNotification'

firebase.initializeApp({
    apiKey: 'AIzaSyCRO9AusoxaGcravcEKVdEPp60C6SP9dJ0',
    authDomain: 'educards-a0893.firebaseapp.com',
    databaseURL: 'https://educards-a0893.firebaseio.com',
    projectId: 'educards-a0893',
    storageBucket: '',
    messagingSenderId: '397057985078',
    appId: '1:397057985078:web:f62577634df4c47cb5a697',
    measurementId: 'G-N6TEYWEP4R',
})
firebase.firestore().enablePersistence()
  .catch((err) => {
      if (err.code == 'failed-precondition') {
          console.log('Multiple tabs open, persistence can only be enabled')
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
          store.addNotification(
            ErrorNotification(
                "Sie haben mehrere Tabs mit Teacher's Guide geoeffnet deshalb ist in diesem Tab kein Offlinezugriff moeglich."
            )
        )

      } else if (err.code == 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
          console.log('The current browser does not support all of the features required to enable persistence')
      }
  });
//LISTENING HERE FOR AUTH CHANGES
const db = admin.firestore()
function App(props) {
    const dispatch = useDispatch()

    useEffect(() => {
        Promise.all([
            db.collection('cards').get(),
            db.collection('categories').get(),
            db.collection('subCategories').get(),
            db.collection('lists').get(),
            db.collection('buyOptions').get(),
            db.collection('aboutUs').get()
        ])
            .then(snapsShots => {
                const result = snapsShots.reduce((obj, collection) => {
                    const collectionMap = {}
                    let type = ''

                    collection.forEach(doc => {
                        const data = doc.data()
                        if (!collectionMap[data._type]) {
                            type = data._type
                        }
                        collectionMap[doc.id] = data
                    })
                    return { ...obj, [type]: collectionMap }
                }, {})
                dispatch(insertData({ data: result }))
            })
            .catch(error => {
                console.log(error)
            })
        firebase.auth().onAuthStateChanged(user => {
            // let unsubscripe = null
            if (user) {
               
                db
                    .collection('users')
                    .doc(user.uid)
                    .onSnapshot(userSnapshot => {
                        if(userSnapshot.data()) {
                            dispatch(
                                loginUser({ ...userSnapshot.data(), id: user.uid })
                            )
                        } else {
                            console.log('Fehler beim Einlogen.')
                        }
                        
                       
                    })
            } else {
                // if (unsubscripe) {
                //     unsubscripe()
                // }
                dispatch(logoutUser())
            }
        })
        // return () => {
        //     console.log('destroy')
        //     if (unsubscripe) {
        //         unsubscripe()
        //     }
        // }
    }, [])

    return (
        <div
            id="app"
            className="min-h-screen bg-blue-100 antialiased xl:flex xl:flex-col xl:h-screen"
        >
            <SiteHeader className="xl:flex-shrink-0" />
            <div className="xl:flex-1 xl:flex xl:overflow-y-hidden">
                <SearchFilters />

                <Content />
            </div>
        </div>
    )
}

export default App
