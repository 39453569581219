import React, { useRef, useState } from 'react'
import { useCloseModalWhenOutsideClick } from '../../hooks/useCloseModalWhenOutsideClick'
import { auth } from 'firebase'
import { store } from 'react-notifications-component'
import SuccessNotification from '../notifications/SuccessNotification'
import ErrorNotification from '../notifications/ErrorNotification'
export default function ForgotPasswort() {
    const wrapperRef = useRef(null)
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [isVisible, setIsVisible] = useState(false)
    useCloseModalWhenOutsideClick(wrapperRef, setIsVisible)
    return (
        <div className="">
            <div className='flex justify-center mt-6"'>
                <div
                    onClick={() => setIsVisible(true)}
                    className="inline-block align-baseline text-sm text-blue-500 hover:text-blue-800"
                >
                    Passwort zurücksetzen
                </div>
            </div>
            {isVisible && (
                <div
                    className="overflow-auto fixed inset-0 w-full h-screen flex items-center justify-center z-10"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                >
                    <div
                        className="max-w-4xl bg-white shadow-md rounded p-8 mt-8"
                        ref={wrapperRef}
                    >
                        <div ref={wrapperRef}>
                            <label className="block text-blue-700 text-sm font-bold mb-2">
                                Email
                            </label>
                            <div className='flex flex-row'>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                placeholder="name@email.de"
                                onChange={e => setEmail(e.target.value)}
                            />
                            <button
                                onClick={(e) =>
                                  {e.preventDefault()
                                    auth()
                                        .sendPasswordResetEmail(email)
                                        .then(result => {
                                          console.log(result)
                                            setIsVisible(false)
                                            setError('')
                                            store.addNotification(
                                                SuccessNotification(
                                                    `Nachricht an ${email} gesendet.`
                                                )
                                            )
                                        })
                                        .catch(err => {
                                          console.log(err)
                                            let translatedError = err.message
                                            if (
                                                translatedError
                                                    .toLowerCase()
                                                    .includes('email')
                                            ) {
                                                translatedError =
                                                    'Die Emailadresse ist ungültig.'
                                            } else if (
                                                translatedError
                                                    .toLowerCase()
                                                    .includes('network')
                                            ) {
                                                translatedError =
                                                    'Probleme mit der Netzwerkverbindung.'
                                            }
                                            store.addNotification(
                                                ErrorNotification(
                                                    translatedError
                                                )
                                            )
                                            setError(translatedError)
                                        })}
                                }
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                                Passwort zurücksetzen
                            </button>
                       
                            </div>
                            <p className='inline-block align-baseline text-sm text-blue-500'>Es kann wenige Minuten dauern bis sie den Link zum Zurücksetzen in Ihrem Postfach haben.</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
