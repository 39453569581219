import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Lists from './Lists'
import SearchResults from './SearchResults'
import CardDetails from './CardDetails'
import MyLists from './MyLists'
import Categories from './Categories'
import Buy from './settings/Buy'
import AboutUs from './AboutUs'
export default function Content() {
    return (
        <div id={'content'} className="xl:flex-1 xl:overflow-x-hidden">
            <main>
                <Switch>
                    <Route path="/search" component={SearchResults} />
                    <Route path="/cards/:id" component={CardDetails} />
                    <Route path="/lists" component={MyLists} />
                    <Route path="/categories" component={Categories} />
                    {/* <Route path="/einstellungen/karten" component={Buy} /> */}
                    <Route path='/ueber-uns' component={AboutUs}/>
                    <Route component={Lists} />
                </Switch>
            </main>
        </div>
    )
}
