import React, { useState, useEffect, FunctionComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import * as H from 'history'
import FilterTypeGroupView from './FitlerGroupView'
import { FilterType } from '../../model/FilterType'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import SearchBar from './SearchBar'
import {
    updatedQueryParam,
    clearQueryParam,
} from '../../redux/actions/search-actions'
import ShowResultsButton from './ShowResultsButton'
import Dropdown from './Dropdown'

interface ISearchFilterProps {
    history: H.History
}

export const SearchFilters: FunctionComponent<ISearchFilterProps> = ({
    history,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const queryParams = useSelector((state: any) => state.search.queryParams)
    const searchValue = useSelector((state: any) => state.search.searchValue)
    const data = useSelector((state: any) => state.data)
    const dispatch = useDispatch()

    const [
        hideScrollToSearchResultsButton,
        setHideScrollToSearchResultsButton,
    ] = useState(true)
    useScrollPosition(({ prevPos, currPos }) => {
        const endPosition = document
            .getElementById('end')
            .getBoundingClientRect().bottom

        if (600 > endPosition) {
            setHideScrollToSearchResultsButton(true)
        } else {
            setHideScrollToSearchResultsButton(false)
        }
    }, [])

    const convertQueryParamsToString = (params, searchValue) => {
        if (
            Object.values(params).filter(
                (param: FilterType) => param.isSelected
            ).length === 0
        ) {
            if (searchValue) {
                return '/search'
            } else {
                return '/'
            }
        } else {
            const query = Object.values(params)
                .filter((param: FilterType) => param.isSelected)
                .reduce(
                    (tempResult: string, param: FilterType) =>
                        tempResult + param._id + '&',
                    ''
                )
                .toString()
                .slice(0, -1) // removes the last &

            return '/search?' + query
        }
    }

    useEffect(() => {
        console.log(queryParams)
        history.push(convertQueryParamsToString(queryParams, searchValue))
    }, [queryParams, searchValue])

    const categories = data.category
        ? Object.values(data.category).sort(
              (c1: any, c2: any) => c1.position - c2.position
          )
        : []

    return (
        <section className="bg-blue-800 xl:w-72   print:hidden ">
            <div className="flex px-4 py-3 xl:hidden justify-between">
                {isOpen &&
                    !hideScrollToSearchResultsButton &&
                    (queryParams.length > 0 || searchValue !== '') && (
                        <ShowResultsButton />
                    )}
                <div className="relative max-w-xs w-full h-full align-middle">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 ">
                        <svg
                            className="h-6 w-6 fill-current text-blue-600"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M16.32 14.9l1.1 1.1c.4-.02.83.13 1.14.44l3 3a1.5 1.5 0 0 1-2.12 2.12l-3-3a1.5 1.5 0 0 1-.44-1.14l-1.1-1.1a8 8 0 1 1 1.41-1.41l.01-.01zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                        </svg>
                    </div>
                    <SearchBar />
                </div>

                <button
                    type="button"
                    className={`ml-4 inline-flex items-center hover:bg-blue-600 focus:outline-none focus:shadow-outline rounded-lg shadow pl-3 pr-4 ${
                        isOpen ? 'bg-blue-600' : 'bg-blue-700'
                    }`}
                    onClick={() => {
                        dispatch(clearQueryParam())
                        setIsOpen(!isOpen)}}
                >
                    <svg
                        className={`h-6 w-6 fill-current text-white`}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        {isOpen ? (
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                            />
                        ) : (
                            <path d="M3 6a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm3 6a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1zm4 5a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4z" />
                        )}
                    </svg>
                    <span className="ml-1 text-white font-medium">Filtern</span>
                </button>
            </div>
            <form
                className={`xl:block xl:h-full xl:flex xl:flex-col xl:justify-between ${
                    isOpen ? 'block' : 'hidden'
                }`}
            >
                <div className="xl:block xl:overflow-y-auto">
                    <p className=" px-4 py-2 block text-sm font-light text-blue-200">
                        Filtern Sie hier unsere Lehrmethoden nach Ihren
                        Bedürfnissen.
                    </p>
                  
                    <Dropdown categories={categories.slice(0,3)} updateQueryParam={updatedQueryParam}/>
                    {categories.slice(3).map((filterTypeGroup: any) => (
                        <FilterTypeGroupView
                            key={filterTypeGroup._id}
                            filterTypeGroup={filterTypeGroup}
                            updateQueryParam={updatedQueryParam}
                        />
                    ))}
                </div>
                <div className="bg-blue-900 px-4 py-4 sm:text-right">
                    <button
                        className="block w-full sm:w-auto sm:inline-block bg-blue-500 hover:bg-blue-400 font-semibold text-white px-4 py-2 rounded-lg xl:block xl:w-full"
                        onClick={e => {
                            e.preventDefault()
                            dispatch(clearQueryParam())
                        }}
                    >
                        Filter aufheben
                    </button>
                </div>
            </form>
            <div id="end" />
        </section>
    )
}

export default withRouter(SearchFilters)
