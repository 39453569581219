import { LOGIN, LOGOUT, ADD_CARD, UPDATE_CREDITS, HAS_PLUS } from "../actions/user-actions"
const baseState = { id: false, cards: {}, credits: 0 }
export const UserReducer = (state = baseState, { type, payload }) => {

  switch (type) {
    case LOGIN:
      if(payload.user.cards) {
        return {...baseState, ...payload.user, cards: payload.user.cards.reduce((obj, card) =>( {...obj, [Object.keys(card)[0]]: true}), {})}
      }
      return {...baseState, ...payload.user}
    case LOGOUT:
      return {...baseState}
    case ADD_CARD: {
      const cards = [state.cards, payload.card]
      return {...state, credits: payload.credits, cards: cards.reduce((obj, card) =>( {...obj, [Object.keys(card)[0]]: true}))}
    }
    case HAS_PLUS: {
      return {...state, hasPlus: true}
    }
    case UPDATE_CREDITS:
      return {...state, credits: (state.credits + payload.credits)}
    default:
      return state
  }
} 