import React from 'react'
import PropTypes from 'prop-types'
import Table from './foundation_blocks/Table'

function TableSection (props) {
  const {table} = props

  return (
    <div className=''>
      <section className=''>
        {table && <Table table={table} />}
      </section>
    </div>
  )
}

TableSection.propTypes = {
  heading: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.arrayOf(PropTypes.object)
}

export default TableSection