import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    updatedQueryParam,
    updateQueryParamRadio,
    removeQueryParam,
} from '../../redux/actions/search-actions'

export default function DropdownCheckbox({
    updateQueryParam,
    subCategories,
    name,
}) {
    const data = useSelector((state: any) => state.data)
    const queryParams = useSelector((state: any) => state.search.queryParams)
    const [isOpen, setIsOpen] = useState(false)
    const dispatch = useDispatch()
    const queryParamIds = queryParams.map(param => param._id)
    return (
        <div className="w-full rounded overflow-hidden shadow-lg  text-blue-300 pb-3">
            <div className="relative">
                <div
                    onClick={e => {
                        e.preventDefault()
                        setIsOpen(!isOpen)
                    }}
                    className="px-4 block "
                ></div>
                <div className="rounded relative pin-t pin-l">
                    {
                        <div
                            className={`px-4 py-2 text-sm font-semibold text-blue-300 block cursor-pointer`}
                            onClick={() => {
                                setIsOpen(!isOpen)

                                subCategories
                                    .filter(subCategoryId =>
                                        queryParamIds.includes(subCategoryId)
                                    )
                                    .forEach(subCategoryId => {
                                        dispatch(
                                            removeQueryParam(
                                                data.subCategory[
                                                    subCategoryId
                                                ]
                                            )
                                        )
                                    })
                            }}
                        >
                            {name}
                            <div className="rounded-sm float-right p-1 bg-blue-600">
                                {isOpen ? (
                                    <svg
                                        className="h-4 w-4 fill-current text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                    >
                                        {' '}
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        className="h-4 fill-current text-white  "
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 129 129"
                                        enable-background="new 0 0 129 129"
                                    >
                                        <g>
                                            <path d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z" />
                                        </g>
                                    </svg>
                                )}
                            </div>
                        </div>
                    }
                    {isOpen && (
                        <div>
                            {subCategories.map((subCategoryId, i) => (
                                <div
                                    key={subCategoryId}
                                    className={`flex-row flex px-4 py-1 block text-blue-200 hover:bg-blue-400 cursor-pointer ${
                                        queryParamIds.includes(subCategoryId)
                                            ? 'bg-blue-500 '
                                            : ''
                                    }`}
                                    onClick={() => {
                                        if (
                                            queryParamIds.includes(
                                                subCategoryId
                                            )
                                        ) {
                                            dispatch(
                                                updateQueryParam({
                                                    ...data.subCategory[
                                                        subCategoryId
                                                    ],
                                                    isSelected: false,
                                                })
                                            )
                                        } else {
                                            dispatch(
                                                updateQueryParam({
                                                    ...data.subCategory[
                                                        subCategoryId
                                                    ],
                                                    isSelected: true,
                                                })
                                            )
                                        }
                                    }}
                                >
                                    <div className="mt-1 mr-2 ">
                                        {queryParamIds.includes(
                                            subCategoryId
                                        ) ? (
                                            <svg
                                                className="float-left fill-current text-blue-100"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                            >
                                                <path d="M6.61 11.89L3.5 8.78 2.44 9.84 6.61 14l8.95-8.95L14.5 4z" />
                                            </svg>
                                        ) : (
                                            <div className="bg-blue-200 h-4 w-4 rounded-sm" />
                                        )}
                                    </div>
                                    {data.subCategory[subCategoryId].name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
