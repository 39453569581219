import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import CardListItem from './CardListItem'
import { useSelector } from 'react-redux'
import Fuse from 'fuse.js'
import Card, { createCard } from '../model/Card'
import useDimensions from '../hooks/useDimensions'

export default function SearchResults(props) {
    const searchValue = useSelector((state: any) => state.search.searchValue)

    const data = useSelector((state: any) => state.data)
    const [result, setResult] = useState([])
    const [filterNames, setFilterNames] = useState([])
    const {targetRef, dimensions} = useDimensions()
 
    useEffect(() => {
        if (data) {
            const parsed = queryString.parse(props.history.location.search)
            const filterBy = {
                filterTypes: Object.keys(parsed),
            }
            setFilterNames(
                filterBy.filterTypes.map(
                    subCategoryId => data.subCategory[subCategoryId].name
                )
            )

            let cardsFilteredByCategory = Object.values(data.card).filter(
                (card: any) =>
                    Object.keys(filterBy).every(filterAttribute => {
                        return filterBy[filterAttribute].every(
                            selectedSubCategory => {
                                return card.categories.some(
                                    cardSubCategoryId => {
                                        return (
                                            cardSubCategoryId ===
                                            selectedSubCategory
                                        )
                                    }
                                )
                            }
                        )
                    })
            )
            if (searchValue.length > 0) {
                const options = {
                    keys: [
                        { name: 'name', weight: 0.35 },
                        { name: 'preview', weight: 0.3 },
                        { name: 'reason', weight: 0.25 },
                        { name: 'idea', weight: 0.2 },
                    ],
                    distance: 5,
                    tokenize: true,
                    includeScore: true,
                    shouldSort: true,
                    threshold: 0.1,
                    id: '_id',
                }
                const fuse = new Fuse(cardsFilteredByCategory, options)
                const cardsMap = cardsFilteredByCategory.reduce(
                    (obj: any, card: any) => ({
                        ...obj,
                        [card._id]: createCard(card),
                    }),
                    {}
                )
                const searchResult = fuse.search(searchValue)
                setResult(
                    searchResult.slice(0,20).map(result => cardsMap[(result as any).item])
                )
          
            } else {
                setResult(
                    cardsFilteredByCategory.sort((c1: Card, c2: Card) => c1.name > c2.name? 1: -1).map((card: any) => createCard(card))
                )
      
            }
        }
    }, [searchValue, data, props])

    return (
        <div ref={targetRef}>
            {filterNames.length > 0 ? (
                <p className="text-blue-800 px-8 mt-4">{result.length} Methoden gefunden für <span className='font-semibold'>{filterNames.reduce((allNames, name)=> `${allNames} ${name},`, ' ').slice(0,-1)}</span>.</p>
            ) : (
                <p className="text-blue-800 px-8 mt-4">{`${result.length} Methoden gefunden.`}</p>
            )}
            {
                <div className="mt-10 sm:mt-0 sm:inline-flex sm:pb-8 flex flex-wrap">
                    {result.map((card, j) => (
                        <div
                            key={j}
                            className={`p-4 sm:mt-0 sm:w-80 sm:flex-shrink-0`}
                        >
                            <CardListItem card={card} compact={dimensions.width<640} />
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}
