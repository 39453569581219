import React, { useState, useEffect } from 'react'
import { createCard } from '../model/Card'
import { useSelector } from 'react-redux'
import CardGroup from './CardGroup'
import admin from 'firebase'
import UnlockList from './UnlockList'

export default function MyLists() {
    const data = useSelector((state: any) => state.data)
    const user = useSelector((state: any) => state.user)
    const [lists, setLists] = useState([])
    useEffect(() => {
        if (user.id) {
            const db = admin.firestore()
            db.collection('users')
                .doc(user.id)
                .get()
                .then(userSnap => {
                    if (userSnap.data() && userSnap.data().lists) {
                        const userData = userSnap.data()
                        let seminarListsRef = db.collection('seminarLists')
                        // Create a query against the collection
                        const userListPromises = []
                        userData.lists.forEach(list => {
                            userListPromises.push(
                                seminarListsRef
                                    .where('_id', '==', Object.keys(list)[0])
                                    .get()
                            )
                        })
                        Promise.all(userListPromises).then(snapShots => {
                            const userLists = []
                            snapShots.forEach(listSnapshot => {
                                if (listSnapshot.empty) {
                                    console.log(
                                        'Kein gültiger Code für Seminarliste.'
                                    )
                                }

                                listSnapshot.forEach(doc =>
                                    userLists.push(doc.data())
                                )
                            })
                            setLists(userLists)
                        })
                    }
                })
        }
    }, [user])
    return (
        <>
               <label className="m-4 block text-blue-700 text-sm font-bold mb-2 ">
                    Haben Sie einen Code aus einem von Ihnen besuchten Seminar?
                </label>
            <UnlockList style={'ml-4 mr-4 w-24 block px-4 py-1 rounded font-semibold text-white bg-blue-500 hover:bg-blue-800'}            />
            <div className={'py-6'}>
                {lists.map((list: any, i) => (
                    <div key={list._id}>
                        <h5 className="text-blue-900 font-bold text-xl px-4">
                            {list.name}
                        </h5>

                        <CardGroup
                            name={list.description}
                            cards={list.cards.map(cardId =>
                                createCard(data.card[cardId])
                            )}
                            dontUseMore={true}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}
