
import * as firebase from 'firebase/app';
import {auth} from 'firebase'



export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const ADD_CARD = 'ADD_CARD'
export const UPDATE_CREDITS = 'UPDATE_CREDITS'
export const HAS_PLUS = 'HAS_PLUS'
export function login(email: string, password: string) {
    return auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
      return firebase.auth().signInWithEmailAndPassword(email, password)
    })

}

export function signUp(email: string, password: string) {
    return auth().createUserWithEmailAndPassword(email, password).then()
}

export function signOut() {
  return auth().signOut().then()
}

export function updateUserEmail(email) {
  auth().languageCode = 'de'
  const user = auth().currentUser
  user.sendEmailVerification().then(function() {
    // Email sent.
  }).catch(function(error) {
    // An error happened.
  });
}

export function loginUser(user) {
  return {
    type: LOGIN,
    payload: {
      user
    }
  }
}

export function addCard(card, credits) {
  return {
    type: ADD_CARD,
    payload: {
      card,
      credits
    }
  }
}

export function updateCredits(credits) {
  console.log(credits)
  return {
    type: UPDATE_CREDITS,
    payload: {
      credits
    }
  }
}

export function addHasPlus() {
  return {
    type: HAS_PLUS,
  }
}

export function logoutUser() {
  console.log('logout')
  return {
    type: LOGOUT,
  }
}

