import React from 'react'
import Modal from '../auth/AuthModal'
import SearchBar from '../search_filter/SearchBar'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { signUp, login, signOut } from '../../redux/actions/user-actions'
import SettingsButton from '../settings/SettingsButton'
import UnlockList from '../UnlockList'

export default function NavBar({ isOpen }) {
    const userId = useSelector((state: any) => state.user.id)
    return (
        <nav
            className={`lg:flex lg:items-center lg:px-4 xl:flex-1 xl:justify-between ${
                !isOpen ? 'hidden' : 'block'
            } `}
        >
            <SearchBar isInTopBar />
            <div className="lg:flex lg:items-center">
                <div className="px-2 pt-2 pb-5 border-b border-gray-800 lg:flex lg:border-b-0 lg:py-0 lg:px-0">
                    <Link
                        className="mt-1 block px-3 py-1 rounded font-semibold text-white hover:bg-blue-800 lg:mt-0 lg:text-lg lg:px-2 lg:ml-2 xl:text-blue-900 xl:hover:bg-blue-200"
                        to="/"
                    >
                        Home
                    </Link>

                    <Link
                        className="mt-1 block px-3 py-1 rounded font-semibold text-white hover:bg-blue-800 lg:mt-0 lg:text-lg lg:px-2 lg:ml-2 xl:text-blue-900 xl:hover:bg-blue-200"
                        to="/categories"
                    >
                        Lehrmethoden
                    </Link>
                    <Link
                        to='/ueber-uns'
                        className="mt-1 block px-3 py-1 rounded font-semibold text-white hover:bg-blue-800 lg:mt-0 lg:text-lg lg:px-2 lg:ml-2 xl:text-blue-900 xl:hover:bg-blue-200"
                    >
                        Über uns
                    </Link>

                    {userId ? (
                        <>
                            <SettingsButton />
                        </>
                    ) : (
                        <>
                            <Modal
                                actionText={'Registrieren'}
                                action={signUp}
                            />{' '}
                            <Modal actionText={'Anmelden'} action={login} />
                        </>
                    )}
                </div>

                {userId && (
                    <div className="lg:hidden px-2">
                        {/* <Link
                            to="/einstellungen/karten"
                            className="mt-1 block px-3 py-1 rounded font-semibold text-white hover:bg-blue-800 lg:mt-0 lg:text-lg lg:px-2 lg:ml-2 xl:text-blue-900 xl:hover:bg-blue-200"
                        >
                            Lehrmethoden erwerben
                        </Link>
                        <Link
                            to=""
                            className="mt-1 block px-3 py-1 rounded font-semibold text-white hover:bg-blue-800 lg:mt-0 lg:text-lg lg:px-2 lg:ml-2 xl:text-blue-900 xl:hover:bg-blue-200"
                        >
                            Meine Lehrmethoden
                        </Link> */}
                        <Link
                            className="mt-1 block px-3 py-1 rounded font-semibold text-white hover:bg-blue-800 lg:mt-0 lg:text-lg lg:px-2 lg:ml-2 xl:text-blue-900 xl:hover:bg-blue-200"
                            to="/lists"
                        >
                            Meine Listen
                        </Link>
                 
                        <UnlockList style={'mt-1 block px-3 py-1 rounded font-semibold text-white hover:bg-blue-800 lg:mt-0 lg:text-lg lg:px-2 lg:ml-2 xl:text-blue-900 xl:hover:bg-blue-200'}/>
                  
                        <a
                            href="#"
                            className="mt-1 block px-3 py-1 rounded font-semibold text-white hover:bg-blue-800 lg:mt-0 lg:text-lg lg:px-2 lg:ml-2 xl:text-blue-900 xl:hover:bg-blue-200"
                        >
                            Profil
                        </a>
                        <button
                            onClick={() => signOut().then()}
                            className="mt-1 block px-3 py-1 rounded font-semibold text-white hover:bg-blue-800 lg:mt-0 lg:text-lg lg:px-2 lg:ml-2 xl:text-blue-900 xl:hover:bg-blue-200"
                        >
                            Abmelden
                        </button>
                    </div>
                )}
            </div>
        </nav>
    )
}
