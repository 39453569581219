import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter, withRouter } from 'react-router-dom'
import Provider from './redux/Provider'
import ScrollToTop from './components/ScrollToTop'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
const Scroll = withRouter(ScrollToTop)
ReactDOM.render(
    <BrowserRouter>
        <Scroll />
        <Provider>
            <ReactNotification />
            <App />
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
