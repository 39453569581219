import React from 'react'
import 'animate.css';
function SuccessNotification({message}) {
    return <div className="p-4 shadow-md bg-blue-800 font-extrabold text-lg text-blue-100 uppercase w-full">{message}</div>
}

export default function(message) {
    return {
        content: <SuccessNotification message={message}/>,
        type: 'success',
        insert: 'top',
        container: 'bottom-right',
        animationIn: ['animated', 'tada'],
        animationOut: ['animated', 'bounceOut'],
        dismiss: {
            duration: 3000,
        },
    }
}
