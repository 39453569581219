import React  from 'react'

export default function ShowResultsButton() {

    return (
      <div
      className="fixed inset-0 w-full h-screen flex items-end justify-end pointer-events-none"
  >
   
            <button
                className="xl:hidden h-12 mb-16 mt-1 mx-4 bg-blue-600 hover:bg-blue-400 text-white font-bold py-2 px-4 border-blue-700 hover:border-blue-500 rounded shadow-md top-0 pointer-events-auto"
                onClick={e => {
                    e.preventDefault()
                    window.scrollTo(
                        0,
                        document.getElementById('end').offsetTop
                    )
                }}
            >
                Ergebnisse anzeigen
            </button>
        </div>
    )
}

