import React from 'react'
function Table(props) {
    const { table } = props

    return (
            <section className="p-1 mt-4 rounded-sm">
                {table.rows.map((row, rowIndex) => {
                    return (
                        <div key={row} className=" flex text-blue-800">
                            {row.cells.map((cell, cellIndex) => (
                                <div
                                    className={`p-1 m-1 ${rowIndex > 0 &&
                                        cellIndex > 0?
                                        'bg-blue-100': 'bg-blue-200'} border-blue-300 rounded-sm border flex-grow w-full overflow-hidden`}
                                    key={cell}
                                >
                                    {cell === '~' ? '' : cell}
                                </div>
                            ))}
                        </div>
                    )
                })}
            </section>
    )
}

export default Table
