import React from 'react'
import { useSelector } from 'react-redux'
import BlockContent from '@sanity/block-content-to-react'
const serializers = {
    types: {
        code: props => (
            <pre data-language={props.node.language}>
                <code>{props.node.code}</code>
            </pre>
        ),
    },
}
export default function AboutUs() {
    const aboutUsData = useSelector((state: any) => state.data.aboutUs)
    const aboutUsDataSorted: any[] = aboutUsData
        ? Object.values(aboutUsData).sort(
              (d1: any, d2: any) => d1.position - d2.position
          )
        : []

    return (
        <>
            {aboutUsDataSorted.length > 0 && (
                <div>
                    <div className="leading-relaxed text-3xl text-blue-800 px-10 py-16 bg-blue-200 text-center">
                        <BlockContent
                            projectId="vdl3i2aa"
                            dataset="production"
                            blocks={aboutUsDataSorted[0].content.de}
                            serializers={serializers}
                        />
                    </div>

                    <div
                        style={{ maxWidth: 800 }}
                        className="p-4 leading-relaxed text-lg text-blue-900"
                    >
                        {aboutUsDataSorted.slice(1).map(data => {
                            return <div className="mb-4">
                                <h3 className="font-extrabold text-xl text-blue-800  uppercase">
                                    {data.header}
                                </h3>
                                <BlockContent
                                    projectId="vdl3i2aa"
                                    dataset="production"
                                    blocks={data.content.de}
                                    serializers={serializers}
                                />
                            </div>
                        })}
                
                        <div className="mb-4">
                            <h3 className="font-extrabold text-xl text-blue-800 mt-4 uppercase">
                                Datenschutz
                            </h3>
                            <div className="flex font-semibold">
                                <a
                                    className="inline-block align-baseline text-blue-500 hover:text-blue-800"
                                    href="/datenschutz.html"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    Datenschutzerklärung
                                </a>
                            </div>
                        </div>
                        {/* <div className="mb-4">
                    <h3 className="font-extrabold text-xl text-blue-800 mt-4 uppercase">
                    Allgemeine Geschäftsbedingungen
                    </h3>
                    <div className="flex font-semibold">
                        <a
                            className="inline-block align-baseline text-blue-500 hover:text-blue-800"
                            href="/agbs.html"
                            target="_blank"
                            rel="noopener"
                        >
                            Allgemeine Geschäftsbedingungen
                        </a>
                    </div>
            
                </div> */}
                    </div>
                </div>
            )}
        </>
    )
}
