import React, { useState, useRef } from 'react'
import Logo from '../header/Logo'
import { useCloseModalWhenOutsideClick } from '../../hooks/useCloseModalWhenOutsideClick'
import ErrorNotification from '../notifications/ErrorNotification'
import { store } from 'react-notifications-component'
import SuccessNotification from '../notifications/SuccessNotification'
import ForgotPassword from './ForgotPassword'
export default function BaseModal({ actionText, action }) {
    const [isVisible, setIsVisible] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [hasSeminarCode, setHasSeminarCode] = useState(false)
    const wrapperRef = useRef(null)
    useCloseModalWhenOutsideClick(wrapperRef, setIsVisible)
    return (
        <div>
            <button
                onClick={() => setIsVisible(true)}
                className="mt-1 block px-3 py-1 rounded font-semibold text-white hover:bg-blue-800 lg:mt-0 lg:text-lg lg:px-2 lg:ml-2 xl:text-blue-900 xl:hover:bg-blue-200"
            >
                {actionText}
            </button>
            {isVisible && (
                <div
                    className="fixed inset-0 w-full h-screen flex items-center justify-center z-10"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                >
                    <div className="w-full max-w-xs">
                        <Logo />
                        <form
                            ref={wrapperRef}
                            className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
                        >
                            <div className="mb-4">
                                <label className="block text-blue-700 text-sm font-bold mb-2">
                                    Email
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                    placeholder="name@email.de"
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="mb-6">
                                <label className="block text-blue-700 text-sm font-bold mb-2">
                                    Passwort
                                </label>
                                <input
                                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-blue-700 mb-3 leading-tight focus:outline-none focus:shadow-outline ${error
                                        .toLocaleLowerCase()
                                        .includes('password') &&
                                        ' border-red-500'}`}
                                    type="password"
                                    placeholder="******************"
                                    onChange={e => setPassword(e.target.value)}
                                />
                                {/* <p className="text-red-500 text-xs italic">
                                    Wähle ein Passwort aus.
                                </p> */}
                            </div>
                            <div className="flex items-center justify-between">
                                <button
                                    onClick={() => setIsVisible(false)}
                                    className="bg-blue-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="button"
                                >
                                    {'Abbrechen'}
                                </button>

                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="button"
                                    onClick={() =>
                                        action(email, password)
                                            .then(result => {
                                                setIsVisible(false)
                                                setError('')
                                                store.addNotification(
                                                    SuccessNotification(
                                                        `${actionText} erfolgreich.`
                                                    )
                                                )
                                            })
                                            .catch(err => {
                                                let translatedError =
                                                    err.message
                                                if (
                                                    translatedError
                                                        .toLowerCase()
                                                        .includes('password')
                                                ) {
                                                    translatedError =
                                                        'Das Passwort ist falsch formatiert.'
                                                } else if (
                                                    translatedError
                                                        .toLowerCase()
                                                        .includes('email')
                                                ) {
                                                    translatedError =
                                                        'Die Emailadresse ist ungültig.'
                                                } else if (
                                                    translatedError
                                                        .toLowerCase()
                                                        .includes('network')
                                                ) {
                                                    translatedError =
                                                        'Probleme mit der Netzwerkverbindung.'
                                                }
                                                store.addNotification(
                                                    ErrorNotification(
                                                        translatedError
                                                    )
                                                )
                                                setError(translatedError)
                                            })
                                    }
                                >
                                    {actionText}
                                </button>
                            </div>

                        { actionText==='Anmelden' &&   <div className="flex justify-center mt-6">
                                <ForgotPassword />
                            </div>}
                            <div className="flex justify-center mt-6 font-semibold">
                                <a
                                    className="inline-block align-baseline text-sm text-blue-500 hover:text-blue-800"
                                    href="/datenschutz.html"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    Datenschutzerklärung
                                </a>
                            </div>
                        </form>
                        <p className="text-center text-blue-500 text-xs">
                            Teacher's Guide
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}
