import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.getElementById('content').scrollTop = 0
    window.scrollTo(
      0,
      document.getElementById('content').offsetTop
  )
  }, [pathname]);

  return null;
}

