import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Logo from './Logo'
import NavBar from './NavBar'

interface ISiteHeaderProps {
    className: string
}
const SiteHeader: React.FunctionComponent<ISiteHeaderProps> = () => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <header className="bg-blue-900 lg:flex lg:items-center lg:justify-between xl:bg-blue-100 xl:shadow-md print:hidden">
            <div className="flex justify-between px-4 py-3 xl:w-72 xl:bg-blue-900 xl:py-3">
                <Link to={`/`}>
                    <Logo />
                </Link>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        onClick={() => setIsOpen(!isOpen)}
                        className="px-2 text-white hover:text-white focus:outline-none focus:text-white"
                    >
                        <svg
                            className="h-6 w-6 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            {isOpen ? (
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                                />
                            ) : (
                                <path
                                    fillRule="evenodd"
                                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                                />
                            )}
                        </svg>
                    </button>
                </div>
            </div>
            <NavBar isOpen={isOpen} />
        </header>
    )
}

export default SiteHeader
