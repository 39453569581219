import React from 'react'
import PropTypes from 'prop-types'
import SimpleBlockContent from './foundation_blocks/SimpleBlockContent'
import styles from './TextSection.module.css'

function Box (props) {
  const {heading, label, text} = props

  return (
    <div className=''>
      <section className='my-4 p-4 bg-blue-200 rounded-sm'>
        {text && <SimpleBlockContent blocks={text} />}
      </section>
    </div>
  )
}

Box.propTypes = {
  heading: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.arrayOf(PropTypes.object)
}

export default Box
