import React from 'react'
import { useDispatch } from 'react-redux'
import { updateSearchValue } from '../../redux/actions/search-actions'
interface ISearchBarProps {
    isInTopBar?: boolean
}
export default function SearchBar(searchBarProps: ISearchBarProps) {
    const dispatch = useDispatch()
    const handleSearchInput = event => {
        dispatch(updateSearchValue({ searchValue: event.target.value }))
    }
    if(searchBarProps.isInTopBar) {
        return (
        
            <div className="hidden xl:block xl:relative xl:max-w-xs xl:w-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg
                        className="h-6 w-6 fill-current text-blue-600"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M16.32 14.9l1.1 1.1c.4-.02.83.13 1.14.44l3 3a1.5 1.5 0 0 1-2.12 2.12l-3-3a1.5 1.5 0 0 1-.44-1.14l-1.1-1.1a8 8 0 1 1 1.41-1.41l.01-.01zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                    </svg>
                </div>
                <input
                    className="block w-full bg-blue-700 placeholder-blue-100 focus:placeholder-blue-700 focus:outline-none focus:bg-blue-100 focus:text-blue-900 text-white rounded-lg pl-10 pr-4 py-2"
                    placeholder="Methoden nach Stichworten suchen"
                    onChange={handleSearchInput}
                />
            <div/>
            </div>)
     
    } else {
        return (
            <input
            className="block w-full bg-blue-700 placeholder-blue-100 focus:placeholder-blue-700 focus:outline-none focus:bg-blue-100 focus:text-blue-900 text-white rounded-lg pl-10 pr-4 py-2"
            placeholder="Methoden suchen"
            onChange={handleSearchInput}
        />
        )
    }
    
    
}
