import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updatedQueryParam } from '../../redux/actions/search-actions'
import DropdownCheckbox from './DropdownCheckbox'

interface IFilterTypeProps {
    filterTypeGroup: any
    updateQueryParam: any
}

export default function FilterTypeGroupView({
    filterTypeGroup,
}: IFilterTypeProps) {
    const data = useSelector((state: any) => state.data)
    const dispatch = useDispatch()
    if (!filterTypeGroup) return null

    return (
        <div className="py-4 border-t border-blue-900 xl:w-full">
            <DropdownCheckbox
                name={filterTypeGroup.name}
                subCategories={filterTypeGroup.subCategories}
                updateQueryParam={updatedQueryParam}
            />
        </div>
    )
}
