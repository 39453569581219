import { SubCategory, createSubCategories } from "./SubCategoryModel"

export default class Card {
  public id: string
  public name: string
  public preview: string
  public content: any
  public isSelected: boolean
  public image: string
  public isFree: boolean
  public categories: SubCategory[]
  public reason: string
  public idea: string
  public contentXXL: string
  constructor(id: string, name: string, content: any, image: string, categories: SubCategory[], reason: string = '', idea: string = '', preview: string = '', isFree: boolean, contentXXL: any) {
    this.name = name
    this.id = id
    this.content = content
    this.isSelected = false
    this.categories = categories
    this.image = image
    this.reason = reason
    this.idea = idea
    this.preview = preview
    this.isFree = isFree || false
    this.contentXXL = contentXXL || null
  }
}

export function createCard(card: ICardInput): Card {
  const { _id: id, name, content, image, categories, reason, idea, preview, isFree, contentXXL } = card
  if(!content && !contentXXL) {
    console.error(`${name} has no content`)
  }
 
  return new Card(id, name, (content?content.de:''), image, categories ? createSubCategories(categories) : [], reason, idea, preview, isFree, contentXXL)
}

export function createCards(cards: ICardInput[]): Card[] {
  return cards.map(card => {
    return createCard(card)
  })
}

interface ICardInput {
  _id: string,
  name: string,
  preview: string,
  isFree: boolean,
  content: any;
  reason: string
  idea: string
  image: string
  categories: {
    _id: string,
    name: string
  }
  contentXXL: any;
}