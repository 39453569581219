import React from 'react'
import CardListItem from './CardListItem'
import data from '../data'
import Card, { createCard } from '../model/Card'
import { useSelector } from 'react-redux'
import CardGroup from './CardGroup'

export default function Lists() {
    const data = useSelector((state: any) => state.data)
    return (
        <div className={'py-6'}>
            {Object.values(data.list)
                .sort((l1: any, l2: any) => l1.position - l2.position)
                .map((list: any, i) => (
                    <div key={list._id}>
                        <h5 className="text-blue-900 font-bold text-xl px-4">
                            {list.name}
                        </h5>

                        <CardGroup
                            name={list.description}
                            cards={list.cards.map(cardId => createCard(data.card[cardId]))}
                            dontUseMore={false}
                        />
                    </div>
                ))}
        </div>
    )
}
