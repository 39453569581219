import React from 'react'
import styled from 'styled-components'

import { createCard } from '../model/Card'
import imageUrlBuilder from '@sanity/image-url'
import BlockContent from '@sanity/block-content-to-react'
import { useSelector } from 'react-redux'
import RenderSections from './sections/RenderSections'
import client from '../client'
import UnlockCard from './UnlockCard'

const builder = imageUrlBuilder(client)
function urlFor(source) {
    return builder.image(source)
}

const serializers = {
    types: {
        code: props => (
            <pre data-language={props.node.language}>
                <code>{props.node.code}</code>
            </pre>
        ),
    },
}

export default function CardDetails({ match }) {
    const data = useSelector((state: any) => state.data)
    const user = useSelector((state: any) => state.user)
    const list = useSelector((state: any) => state.data.list)
    const card =
        data.card[match.params.id] && createCard(data.card[match.params.id])
    let anwendung = (
        <div className="font-extrabold text-xl text-blue-800 mt-4">
            {`Der weitere Inhalt dieser Karte ist nur für${
                user && user.id ? '' : ' angemeldete'
            }`} Nutzer sichtbar, die dieses Thema im Rahmen einer Veranstaltung mit <a className='text-blue-500 ' href='https://www.thomasberntschulte.de/methodik-und-didaktik/'>Thomas B. Schulte</a> bearbeitet haben.
        </div> 
    )


    return !card ? (
        <div />
    ) : (
        <div>
            <div
                className="md:hidden bg-local inset-0 h-72 w-full bg-cover flex items-center justify-center"
                style={{
                    backgroundImage: `url(${urlFor(card.image)
                        .width(800)
                        .url()})`,
                }}
            >
                <h3
                    style={{ background: 'rgba(234, 248, 255, 0.8)' }}
                    className="text-center w-full font-extrabold text-4xl text-blue-800 p-2"
                >
                    {card.name}
                </h3>
            </div>

            <div className="p-8 md:flex md:justify-center">
                <div className="w-48 hidden md:block mr-8 text-center">
                    <img
                        className="inset-0 h-72 w-full rounded-lg shadow-md object-cover"
                        src={urlFor(card.image)
                            .width(400)
                            .url()}
                        alt=""
                    />
                </div>

       

                <Content className="leading-relaxed text-lg text-blue-900 ">
                    <h2 className="hidden md:block font-extrabold text-2xl text-blue-700 text-shadow-md uppercase">
                        {card.name}
                    </h2>
                    <h3 className="font-extrabold text-xl text-blue-800 mt-4 uppercase">
                        Zweck
                    </h3>
                    <div>{card.reason}</div>
                    <h3 className="font-extrabold text-xl text-blue-800 mt-4 uppercase">
                        Idee
                    </h3>
                    <div>{card.idea}</div>
                    {(() => {
                        if (
                            (card.isFree || (user.id && list && list['f3ad7428-da4a-416c-a523-c8fd7991b133'] && list['f3ad7428-da4a-416c-a523-c8fd7991b133'].cards.includes(card.id))) ||
                            ((user.id && user.hasPlus) ||
                                (card && user.cards[card.id]))
                        ) {
                            anwendung = card.contentXXL ? (
                                <>
                                    <h3 className="font-extrabold text-xl text-blue-800 mt-4 uppercase">
                                        Anwendung
                                    </h3>
                                    <RenderSections
                                        sections={
                                            card.contentXXL || card.content
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <h3 className="font-extrabold text-xl text-blue-800 mt-4 uppercase">
                                        Anwendung
                                    </h3>
                                    <BlockContent
                                        projectId="vdl3i2aa"
                                        dataset="production"
                                        blocks={card.contentXXL || card.content}
                                        serializers={serializers}
                                    />
                                </>
                            )
                        } else if(user.id){
                            // anwendung = (
                            //     <UnlockCard
                            //         card={card}
                            //         credits={(user && user.credits) || 0}
                            //     />
                            // )
                        }
                        return anwendung
                    })()}
                </Content>
            </div>
        </div>
    )
}

const Content = styled.div`
    max-width: 600px;
    p:after {
        content: '';
        display: inline-block;
        width: 0px;
    }

    li {
        margin-left: 20px;
    }
`
