import React, { useState, useRef } from 'react'
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader'
import { auth } from 'firebase'
import SuccessNotification from './notifications/SuccessNotification'
import ErrorNotification from './notifications/ErrorNotification'
import { store } from 'react-notifications-component'
import { useCloseModalWhenOutsideClick } from '../hooks/useCloseModalWhenOutsideClick'
export default function UnlockList({style}) {
    const [listCode, setListCode] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [isVisible, setIsVisible] = useState(false)
    const wrapperRef = useRef(null)
    useCloseModalWhenOutsideClick(wrapperRef, setIsVisible)
    return (
        <>
         <div>
            <div
                onClick={() => setIsVisible(true)}
                className={style}
            >
                Einlösen
            </div>
            {isVisible && (
                <div
                    className="fixed inset-0 w-full h-screen flex items-center justify-center z-10"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                >
            {loading && (
                <div className="fixed inset-0 w-full h-screen flex items-center justify-center z-10 pointer-events-auto">
                    <div className="bg-blue-100 rounded-full shadow-xl p-20">
                        <ClimbingBoxLoader
                            sizeUnit={'px'}
                            size={25}
                            color={'#2C5282'}
                            loading={loading}
                        />
                    </div>
                </div>
            )}
            <form className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'ref={wrapperRef}>
                <label className="block text-blue-700 text-sm font-bold mb-2 ">
                    Haben Sie einen Code aus einem von Ihnen besuchten Seminar?
                </label>
                <div className='flex flex-row justify-between'>

                <input
                    className={`p-2 h-12 w-64 shadow appearance-none border rounded text-blue-700 mb-3 leading-tight focus:outline-none focus:shadow-outline ${error &&
                      'border-red-500'}`}
                      type="text"
                      value={listCode}
                      placeholder="Geben Sie hier Ihren Code ein."
                    onChange={e => setListCode(e.target.value)}
                    />
                <button
                         className={`${
                          listCode.length > 3
                                ? 'bg-blue-500 hover:bg-blue-700 '
                                : 'bg-blue-200'
                        } text-white font-bold px-4 h-12 rounded focus:outline-none focus:shadow-outline`}
                        type="button"
                    onClick={() => {
                      if (listCode.length < 4) {
                        setError('Ein Code muss länger als 3 Zeichen sein.')
                        return
                      }
                      setError('')
                      setLoading(true)
                      auth()
                      .currentUser.getIdToken()
                      .then(token => {
                        fetch(
                          'https://europe-west1-educards-a0893.cloudfunctions.net/api/redeemList',
                          {
                            method: 'post',
                            headers: {
                                            Accept: 'application/json',
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify({
                                          token,
                                            listCode,
                                        }),
                                      }
                                )
                                    .then(response => {
                                      if (response.status === 404) {
                                            throw new Error(
                                                'Kein gültiger Code für ein Seminar.'
                                            )
                                          } else if (response.status === 406) {
                                            throw new Error(
                                                `Sie haben ${listCode} schon eingelöst.`
                                                )
                                              }
                                        return response.json()
                                      })
                                    .then(() => {
                                        store.addNotification(
                                          SuccessNotification(
                                                `Freischaltung erfolgreich`
                                            )
                                        )
                                        setListCode('')
                                        setLoading(false)
                                      })
                                      .catch(err => {
                                        store.addNotification(
                                          ErrorNotification(err.message)
                                          )
                                          setLoading(false)
                                          setError(err.message)
                                    })
                                  })
                    }}
           
                >
                    {'Einlösen'}
                </button>
                    </div>
                {error && (
                    <span className="ml-4 text-red-500 text-xs italic font-bold">
                        {error}
                    </span>
                )}
            </form>
            </div>
            )
            }
            </div>
        </>
    )
}
