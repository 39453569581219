import { UPDATE_SEARCH_VALUE, UPDATE_QUERY_PARAM, CLEAR_QUERY_PARAM, UPDATE_QUERY_PARAM_RADIO, REMOVE_QUERY_PARAM } from "../actions/search-actions";

export const searchReducer = (state = { searchValue: '', queryParams: [] }, { type, payload }) => {
  switch (type) {
    case UPDATE_SEARCH_VALUE:
      return { ...state, searchValue: payload.searchValue }
    case UPDATE_QUERY_PARAM:
      if (payload.queryParam.isSelected) {
        return { ...state, queryParams: [...state.queryParams, payload.queryParam] }
      } else {
        return { ...state, queryParams: [...state.queryParams.filter(param => param._id !== payload.queryParam._id)] }
      }

    case UPDATE_QUERY_PARAM_RADIO:
      return { ...state, queryParams: [...state.queryParams, payload.queryParams.add].filter(param => param._id !== payload.queryParams.remove._id) }
    case REMOVE_QUERY_PARAM:
      return { ...state, queryParams: [...state.queryParams].filter(param => param._id !== payload.queryParam._id) }
    case CLEAR_QUERY_PARAM:
      return { ...state, queryParams: [] }

    default:
      return state
  }
} 