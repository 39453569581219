import React, { useState } from 'react'
import CardListItem from './CardListItem'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../slicki.css'
import useDimensions from '../hooks/useDimensions'
export default function CardGroup({ name, cards, dontUseMore }) {
    const [showMore, setShowMore] = useState(dontUseMore)
    const shownCards = showMore ? cards : cards.slice(0, 3)
    const { dimensions, targetRef } = useDimensions()

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }
    return (
        <div ref={targetRef}>
            {!dontUseMore && (
                <div className="px-4">
                    <div className="flex justify-between">
                        <h5 className="text-blue-900 text-m">{name}</h5>
                        {!showMore ? (
                            <h3
                                onClick={() => setShowMore(true)}
                                className="hidden sm:block h-8 px-3 py-1 rounded font-semibold text-white  bg-blue-400 hover:bg-blue-500 sm:text-sm sm:px-2"
                            >
                                mehr
                            </h3>
                        ) : (
                            <h3
                                onClick={() => setShowMore(false)}
                                className="hidden sm:block h-8 px-3 py-1 rounded font-semibold text-white  bg-blue-400 hover:bg-blue-500 sm:text-sm sm:px-2"
                            >
                                weniger
                            </h3>
                        )}
                    </div>
                </div>
            )}

            {dimensions.width < 640 ? (
                <>
                    <Slider className="mx-8 mb-16 mt-2" {...settings}>
                        {shownCards.map((card, j) => (
                            <CardListItem key={j} card={card} />
                        ))}
                    </Slider>
                    {!dontUseMore && (
                        <div className="flex flex-row justify-center mb-6">
                            {!showMore ? (
                                <h3
                                    onClick={() => setShowMore(true)}
                                    className="block h-8 px-3 py-1 rounded font-semibold text-white  bg-blue-400 hover:bg-blue-500 sm:text-sm sm:px-2"
                                >
                                    mehr
                                </h3>
                            ) : (
                                <h3
                                    onClick={() => setShowMore(false)}
                                    className="block h-8 px-3 py-1 rounded font-semibold text-white  bg-blue-400 hover:bg-blue-500 sm:text-sm sm:px-2"
                                >
                                    weniger
                                </h3>
                            )}
                        </div>
                    )}
                </>
            ) : (
                <div className="sm:overflow-x-auto sm:overflow-y-hidden">
                    <div className="sm:inline-flex sm:pb-8 flex flex-wrap">
                        {shownCards.map((card, j) => (
                            <div
                                key={j}
                                className={`p-4 mt-12 sm:mt-0 sm:w-80 sm:flex-shrink-0`}
                            >
                                <CardListItem card={card} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
